/* BestSelling */
.best-sellingDecor {
  width: 196px;
  position: absolute;
  top: 40px;
  z-index: 1;
}
.title {
  font-size: 30px;
  font-weight: 700;
  color: #d327a1;
  margin-bottom: 2rem;
}
.freespan {
  font-size: 30px !important;
  font-weight: 500 !important;
  color: #363636;
}
.freebies-head {
  color: #d72956;
  font-size: 60px;
  font-weight: 700;
}
.explore-btn {
  background-color: #d327a1 !important;
  height: 40px;
  width: 200px;
  margin-left: 1rem !important;
}

@media (max-width: 768px) {
  .title {
    font-size: 20px;
    font-weight: 700;
    color: #d327a1;
    margin-bottom: 2rem;
  }
  .Embroideryview-count {
    display: none;
  }
  .best-sellingDecor {
    width: 196px;
    position: absolute;
    top: 30px;
    z-index: 1;
  }
  .freebies-head {
    color: #d72956;
    font-size: 42px;
    font-weight: 700;
  }
}

/* Card */
.card-img {
  width: 292px;
  cursor: pointer;
  height: 350px;
  object-fit: cover;
}
@media (max-width: 768px) {
  .card-img {
    width: 292px;
    cursor: pointer;
    height: 185px;
  }
}
.card-details {
  text-align: center;
}
.card-details h6 {
  font-size: 14px;
  font-weight: 600;
  color: #636363;
}
.card-details p {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.card-price {
  font-weight: 600;
  font-size: 18px;
}
.product-icon {
  position: absolute;
  bottom: 25%;
  width: 100%;
}
.icons {
  background-color: #ffedf3;
  margin: 0px 1rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.count {
  opacity: 0;
  transition: 0.5s;
  font-size: 14;
  font-weight: 600;
}

.card-hover:hover .icons {
  opacity: 1;
}
.card-hover:hover .count {
  opacity: 1;
  color: #d327a1;
}
@media (max-width: 768px) {
  .card-details h6 {
    font-size: 12px;
    font-weight: 600;
    color: #636363;
  }
  .card-details p {
    font-size: 12px;
    font-weight: 500;
    color: #000;
  }
  .card-price {
    font-weight: 600;
    font-size: 16px;
  }
  .product-icon {
    position: absolute;
    bottom: 35%;
    width: 100%;
  }
  .icons {
    opacity: 1;
    margin: 0px 4px;
  }
  .count {
    display: none !important;
  }
  .css-1ay4ekd {
    /* margin:2rem 0px ; */
    /* padding: 2rem 0rem */
    width: 90% !important;
    overflow-y: scroll;
    height: 100vh;
    background-color: #ffedf3 !important;
  }
}

/* Footer */
.footer-logo {
  width: 159px;
  cursor: pointer;
}
.mail-icon {
  width: 20px;
}
.mail-id {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-left: 5px;
}
.media-icon {
  width: 24px;
}
.follow {
  font-size: 14px;
  font-weight: 700;
  color: #6f6f6f;
}
.quick-links h3 {
  font-size: 16px;
  font-weight: 700;
  color: #383838;
}
.quick-links ul li {
  list-style-type: none;
  padding-top: 10px;
}
.quick-links ul li a {
  text-decoration: none;
  color: #393939;
  font-size: 14px;
  font-weight: 600;
}
.quick-links p {
  color: #393939;
  font-size: 14px;
  font-weight: 600;
}
.quick-links input {
  /* width: 250px; */
  height: 30px;
  border: 1px solid #d327a1;
  /* border-color: #D327A1; */
  border-radius: 5px;
  padding-left: 10px;
}
.quick-links input::placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #818181;
}
.quick-links button {
  height: 30px;
  background-color: #d327a1 !important;
  margin-left: 10px;
}

/* Embroidery Design */
.embroidery-img {
  width: 100%;
  height: 503px;
}
.embroidery-img2 {
  width: 100%;
  /* height: 503px; */
}
.embroidery-content {
  background-color: #fff;
  border-radius: 20px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  /* Hide scrollbar for WebKit browsers */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.embroidery-content::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
.product-description h3 {
  font-size: 18px;
  font-weight: 700;
  color: #d327a1;
}
.product-description p {
  font-size: 16px;
  font-weight: 500;
}
.view-table td {
  border: none;
  font-size: 16px;
  font-weight: 600;
}
.heart-icon {
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: rgba(255, 20, 147, 0.5);
  cursor: pointer;
  margin-left: 2rem;
  border: 1px solid #d327a1;
}
.embroidery-title {
  font-size: 25px;
  font-weight: 700;
}
.embroidery-rating {
  font-size: 14px;
  font-weight: 500;
  color: #0a0a0a;
  margin-left: 0.5rem;
}
.embroidery-totalbuyers {
  font-size: 16px;
  font-weight: 700;
}
.embroidery-Category {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}
.embroidery-price {
  font-size: 25px;
  font-weight: 600;
}
.addtocart-btn {
  height: 35px;
  width: 160px;
  background-color: #d327a1 !important;
}
.share-btn {
  width: 36px !important;
  height: 35px;
  background-color: #d327a1 !important;
}
.view-table {
  margin-left: 1.5rem !important;
}
.Embroideryview-count {
  font-size: 16px;
  font-weight: 500;
}
.sort-dropdown {
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 2rem;
  cursor: pointer;
}
.product-heigth {
  height: 503px;
}
.customer-reviews h3 {
  font-size: 18px;
  font-weight: 700;
  color: #d327a1;
}
.review-date {
  font-size: 14px;
  font-weight: 500;
}
.sm-padd {
  padding: 10px 0px;
}
@media (max-width: 768px) {
  .addtocart-btn {
    height: 35px;
    width: 140px;
    background-color: #d327a1 !important;
  }
  .embroidery-price {
    font-size: 22px;
    font-weight: 700;
  }
}
.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #ffedf3 !important;
  color: #000 !important;
  font-weight: 600 !important;
}

/* Shopping-Cart */
.cart-img {
  width: 100px;
  height: 100px;
  border: 1px solid #d327a1;
}
.cart-table th {
  padding: 0px;
  border: none;
}
.cart-table td {
  padding: 3px 0px;
  border: none;
  font-size: 16px;
  font-weight: 600;
}
.cart-cancelicon {
  font-size: 1.3rem;
  margin-right: 1rem;
}
.cart-btn {
  background-color: #d327a1 !important;
  width: 160px !important;
  border-radius: 5px !important;
  font-weight: 500 !important;
  height: 35px !important;
  font-size: 14px !important;
}
.cart-totalrow {
  border-top: 1px solid #000 !important;
  padding-top: 1rem !important;
}
@media (max-width: 768px) {
  .cart-img {
    width: 50px;
    height: 50px;
    border: 1px solid #d327a1;
  }
  .cart-table td {
    padding: 3px 0px;
    border: none;
    font-size: 12px;
    font-weight: 500;
  }
  .cart-table th {
    padding: 0px;
    border: none;
    font-size: 12px;
    font-weight: 600;
  }
  .cart-btn {
    width: 80px;
    padding: 2px 0px !important;
  }
  .price {
    padding-left: 1px !important;
  }
  .Category {
    padding-right: 4px !important;
  }
  .product-name-tr {
    padding-left: 4px !important;
  }
  .css-1sw89g6 {
    width: 360px !important;
  }
  .cart-btn {
    background-color: #d327a1 !important;
    width: 80px !important;
    border-radius: 5px !important;
    font-weight: 500 !important;
    height: 25px !important;
    font-size: 10px !important;
  }
}
.price-modal td {
  font-size: 14px;
  font-weight: 700;
  color: #1c1c1c;
  border-bottom: 1px solid #b5b5b5;
  padding: 1rem 0rem;
}
.coupon-head {
  font-size: 14px;
  font-weight: 700;
  color: #1c1c1c;
}
.apply-btn {
  position: absolute !important;
  right: 0;
  background-color: #d327a1 !important;
  height: 45px;
  border-radius: 50px !important;
  width: 100px;
}
.coupon-input {
  height: 45px;
  width: 100%;
  padding-left: 1rem;
  border-radius: 50px;
  border: 1px solid #d327a1;
}
.checkout-btn {
  background-color: #d327a1 !important;
}
.close-icon {
  font-size: 1.4rem;
  cursor: pointer;
}
.css-1sw89g6 {
  padding: 0px !important;
}
.purchase-Head {
  border: 1px solid #d327a1;
  padding: 0.5rem;
  border-radius: 10px;
}
.payment-modal {
  border: 1px solid #d327a1;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px 0px;
}
.razorpay {
  background-color: #d327a1;
  height: 76px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.razorpay img {
  width: 51px;
  height: 9px;
}
.payment-modal h5 {
  font-size: 14px;
  font-weight: 700;
  color: #454545;
}
.payment-modal p {
  font-size: 12px;
  font-weight: 600;
  color: #454545;
}
.debit-cardimg {
  height: 20px !important;
  width: 20px !important;
}
/* My order */
.order-img {
  width: 90px;
  border: 1px solid #d327a1;
}
.order-row td {
  padding: 0px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: none;
}
.order-pay {
  background-color: #d327a1 !important;
  width: 160px !important;
  height: 35px !important;
  border-radius: 5px !important;
}
.download-order {
  background-color: #2d2d2d !important;
  padding: 2px 4px !important;
  border-radius: 0px !important;
}
.order-th th {
  border-bottom: none;
  background-color: transparent;
}

@media (max-width: 768px) {
  .order-th th {
    font-size: 12px;
    font-weight: 600;
  }
  .order-img {
    width: 45px;
    border: 1px solid #d327a1;
  }
  .order-row td {
    padding: 0px;
    font-size: 12px;
    font-weight: 500;
    border-bottom: none;
  }
  .order-pay {
    background-color: #d327a1 !important;
    width: 50px;
    font-size: 10px !important;
    padding: 0px 0px !important;
  }
  .download-order {
    background-color: #2d2d2d !important;
    padding: 2px 2px !important;
    border-radius: 0px !important;
    font-size: 8px !important;
    width: 70px;
  }
  .order-pay {
    background-color: #d327a1 !important;
    width: 90px !important;
    height: 25px !important;
    border-radius: 5px !important;
  }
}
:where(.css-dev-only-do-not-override-3rel02).ant-select-single
  .ant-select-selector {
  border-radius: 0px !important;
  border-color: #4f4f4f !important;
  width: 150px !important;
  height: 34px !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-select .ant-select-arrow {
  right: -10px !important;
  /* display: none !important; */
  color: #000;
}
.custom-size {
  border-radius: 0px !important;
  border: 1px solid #4f4f4f;
  width: 150px !important;
  height: 34px !important;
}
.custom-dropdown {
  width: 150px !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-upload-wrapper
  .ant-upload-drag {
  width: 100% !important;
  border-color: #000;
  border-radius: 0px;
  height: 100px;
}
:where(.css-dev-only-do-not-override-3rel02).ant-upload-wrapper
  .ant-upload-drag:not(.ant-upload-disabled):hover,
:where(.css-dev-only-do-not-override-3rel02).ant-upload-wrapper
  .ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #d327a1;
}
.textarea {
  width: 100%;
  border: 1px solid #d327a1;
}
.sendquote-btn {
  background-color: #d327a1 !important;
}
.seemore-btn {
  background-color: #d327a1 !important;
  border-radius: 0px !important;
}

/* CenterMode.css */

.slider-container {
  padding: 20px;
  /* background-color: #f5f5f5; */
}

.carousel-slide {
  padding: 10px;
}

.carousel-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
}

.slick-center .carousel-image {
  transform: scale(1.2);
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

/* Header */
.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
}
.css-13hrbq3 {
  border-radius: 50px !important;
  box-shadow: 0px 3px 4px 0px #ff97b9 !important;
  height: 35px;
  color: #ababab !important;
}
.head-Icon {
  color: #d327a1;
  font-size: 24px;
}
.ReactFlagsSelect-module_label__27pw9,
.ReactFlagsSelect-module_secondaryLabel__37t1D {
  color: #000;
}
.ReactFlagsSelect-module_selectBtn__19wW7 {
  border: none !important;
}
.navlink {
  font-size: 16px;
  font-weight: 700;
  color: #292929;
  margin-bottom: 0px;
  text-decoration: none;
}
.sub-navlinks {
  position: absolute;
  /* transform: translateY(210px); */
  z-index: 99;
  display: flex;
  right: 20px;
  background-color: #ffedf3;
  padding: 1.5rem;
}
.sub-navlinks ul li {
  list-style-type: none;
}
.sub-navlinks ul li a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.mobile-menu {
  font-size: 20px;
  font-weight: 500;
}

/* Coupon */
.coupon-desc h3 {
  font-size: 25px;
  font-weight: 700;
}
.coupon-desc h4 {
  font-size: 20px;
  font-weight: 700;
}
.coupon-desc p {
  font-size: 16px;
  font-weight: 500;
}
.coupon-desc h6 {
  font-size: 16px;
  font-weight: 600;
}
.coupon-img {
  width: 90%;
}

/* Signup */
.signupbox h3 {
  font-size: 20px;
  font-weight: 700;
  color: #d327a1;
}
.signupbox label {
  font-size: 16px;
  font-weight: 600;
}
.signupbox input {
  height: 35px;
  border: 1px solid #d327a1;
  border-radius: 5px;
  width: 100%;
}
.signupbox button {
  background-color: #d327a1 !important;
  height: 35px;
  width: 130px;
}
.signupbox span {
  font-size: 14px;
  font-weight: 600;
  color: #1a1a1a;
}
.signupbox a {
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  color: #1a1a1a;
  cursor: pointer;
}
.profile-table td {
  font-size: 16px;
  padding: 0.5rem;
  font-weight: 600;
}
.signup-Head {
  position: relative;
}
.signup-Head::after {
  bottom: -10px;
  height: 3px;
  width: 170px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  display: block;
  background: linear-gradient(45deg, #ff97b9, transparent);
}
.profile-Head {
  position: relative;
}
.profile-Head::after {
  bottom: -10px;
  height: 3px;
  width: 60px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  display: block;
  background: linear-gradient(45deg, #ff97b9, transparent);
}
.login-Head {
  position: relative;
}
.login-Head::after {
  bottom: -10px;
  height: 3px;
  width: 60px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  display: block;
  background: linear-gradient(45deg, #ff97b9, transparent);
}
.offcanvas.offcanvas-start {
  width: 80vw;
}

/* Banner.css */
@keyframes moveFromTopRight {
  0% {
    transform: translate(100%, -100%);
    opacity: 0;
  }
  50% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes blurAndMoveToBottomLeft {
  0% {
    transform: translate(0, 0);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    transform: translate(-100vw, 100vh);
    filter: blur(10px);
    opacity: 0;
  }
}

.banner-img {
  width: 350px;
  height: auto;
  position: absolute;
  animation: moveFromTopRight 2s ease-out forwards,
    blurAndMoveToBottomLeft 2s 2s ease-in forwards;
}

.banner-container {
  position: relative;
  height: 500px;
}
.banner-head {
  font-size: 60px;
  font-weight: 400;
  font-family: "Norican", cursive !important;
}
.banner-para {
  font-size: 16px;
  font-weight: 400;
  color: #303030;
  font-family: "Perpetua";
}
.banner-section {
  background-image: url("../public/assets/bannerback.png");
  /* width: 100v; */
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: cover; */
}
.mobile-banner {
  display: none;
}
.or {
  margin: 0px 10px;
}
@media (max-width: 768px) {
  .mobile-banner {
    display: block;
    background-color: #ffcede;
  }
  .bies-bannerimg {
    width: 200px;
    padding: 1rem;
  }
  .bies-box {
    text-align: center;
  }
  .css-1jgoave {
    width: 375px !important;
  }
  .or {
    margin: 0px 2px;
  }
  .ant-upload-text {
    font-size: 10px !important;
  }
  .css-19duhbx {
    width: 350px !important;
  }
  .md-search {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .md-search {
    display: none !important;
  }
  .custom-dropdown {
    width: 135px !important;
  }
  :where(.css-dev-only-do-not-override-3rel02).ant-select-single
    .ant-select-selector {
    width: 135px !important;
  }
  .custom-size {
    width: 135px !important;
  }
}
.mobile-ul li {
  list-style-type: none;
  padding: 10px 0px;
}
.mobile-ul li a {
  text-decoration: none;
  color: #000;
}
.coustom-subHead {
  font-size: 18px;
  font-size: 700;
}

/* Rating */
.rate-box h4 {
  font-size: 16px;
  font-weight: 600;
  color: #1e1e1e;
}
.rate-box textarea {
  border-radius: 10px;
}
.review-head {
  font-size: 18px;
  font-weight: 600;
}
/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}
.css-imxc6v-MuiBadge-badge {
  background-color: #ffcede !important;
  color: #000 !important;
}
.css-hyum1k-MuiToolbar-root {
  padding: 0px !important;
}
.css-5poeop {
  background-color: #fff !important;
}
.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
}
.css-1omp2b7.Mui-selected {
  background-color: #ffedf3 !important;
}
:where(.css-3rel02).ant-select-single .ant-select-selector {
  border-radius: 0px !important;
  border-color: #000 !important;
}
:where(.css-3rel02).ant-select-single {
  width: 100% !important;
}
:where(.css-3rel02).ant-select-single {
  width: 150px !important;
  border-radius: 0px !important;
  border-color: #000 !important;
}
.css-2ubntf {
  background-color: #ffcede !important;
  color: #000 !important;
}
.button-medium {
  width: 160px !important;
  height: 35px !important;
  border-radius: 5px !important;
  background-color: #d327a1 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
}
.ratingicon {
  font-size: 22px;
  color: #d327a1;
}
.downloadFile {
  font-size: 22px;
  color: #2d2d2d;
  cursor: pointer;
}
.dis-apply-btn {
  background-color: #dc6dbd !important;
  color: #fff !important;
  position: absolute !important;
  right: 0;
  height: 45px;
  border-radius: 50px !important;
  width: 100px;
}
/* .slick-prev, .slick-next{
  display: none !important;
} */
.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}
.rating-sm {
  display: none;
}
.rating-lg {
  display: block;
  text-decoration: none;
  color: #474747;
}
.download-order {
  display: block;
}
.downloadFile {
  display: none;
}
.mobile-flag {
  display: none;
}
.success-gif {
  width: 150px;
}
.asd {
  padding: 1.5rem 0rem;
}
.asd p {
  font-size: 18px;
  font-weight: 600;
}
.asd button {
  background-color: #dc6dbd !important;
  color: #fff !important;
  font-weight: 500 !important;
  height: 40px !important;
  width: 150px !important;
}
@media (max-width: 992px) {
  .success-gif {
    width: 120px;
  }
  .asd {
    padding: 1rem 0rem;
  }
  .asd p {
    font-size: 14px;
    font-weight: 600;
  }

  .rating-sm {
    display: block;
    text-decoration: none;
  }
  .rating-lg {
    display: none;
  }
  .download-order {
    display: none !important;
  }
  .downloadFile {
    display: block;
  }
  .mobile-flag {
    display: block;
  }
  .order-pay {
    width: 100px !important;
  }
  .md-container {
    padding: 0px !important;
  }
  .md-sub {
    margin: 0px !important;
  }
  .custom-dropdown {
    width: 135px !important;
  }
  :where(.css-dev-only-do-not-override-3rel02).ant-select-single
    .ant-select-selector {
    width: 135px !important;
  }
  .custom-size {
    width: 135px !important;
  }
  .custom-dropdown {
    width: 135px !important;
  }
  :where(.css-dev-only-do-not-override-3rel02).ant-select-single
    .ant-select-selector {
    width: 135px !important;
  }
  .custom-size {
    width: 135px !important;
    /* margin-left: 8px; */
  }
  .banner-head {
    font-size: 50px;
  }
}
.con {
  margin-top: 3rem;
}
@media (max-width: 576px) {
  .con {
    margin-top: 0rem !important;
  }
}
.faq-dec {
  width: 100px !important;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 10px 0px !important;
}
.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {
  margin: 5px 0px !important;
}
.css-eqpfi5-MuiAccordionSummary-content {
  font-weight: 600 !important;
  font-size: 16px !important;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root {
  margin: 8px 0px !important;
}
.policy-content p {
  font-size: 16px;
  font-weight: 500;
}
.policy-content h2 {
  font-size: 20px;
  font-weight: 700;
}
.policy-content h3 {
  font-size: 16px;
  font-weight: 700;
}
.policy-content p a {
  text-decoration: none;
  color: #000;
}
.delivery-info p {
  font-size: 16px;
  font-weight: 500;
}
.delivery-info h3 {
  font-size: 16px;
  font-weight: 700;
}
.delivery-info ul li {
  font-size: 16px;
  font-weight: 500;
}
.privacy h2 {
  font-size: 20px;
  font-weight: 700;
}
.privacy p {
  font-size: 16px;
  font-weight: 500;
}
.privacy ul li {
  font-size: 16px;
  font-weight: 500;
}
.about p {
  text-indent: 40px;
  font-size: 16px;
  font-weight: 500;
}
.css-imxc6v-MuiBadge-badge {
  width: 20px !important;
}
.product-desc {
  color: #d357a7;
  font-size: 20px;
  font-weight: 700;
  padding-top: 20px;
}
.free-btn {
  background-color: #d357a7;
  color: #fff;
  text-decoration: none;
  padding: 5px 30px;
  border-radius: 5px;
  font-weight: 600;
}
.paypal-div {
  height: 60vh !important;
  overflow-y: scroll !important;
}
.pay-btn {
  background-color: #ffcede !important ;
  color: #000 !important;
  font-weight: 600 !important;
}
.updatebtn {
  background-color: #d357a7 !important;
  margin-top: 1rem !important;
}
.login-box {
  width: 90%;
}
.Keywords {
  color: #d357a7;
  font-size: 20px;
  font-weight: 700;
}
.keytags {
  background-color: #f7f7f7;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 400;
}
.table-details {
  width: 70%;
}
.table-details tr:first-child th:first-child {
  width: 30%;
}
.css-l0jafl.Mui-expanded {
  font-weight: 600 !important;
}

.odd {
  color: #d357a7;
  font-size: 16px;
  font-weight: 600;
}
.sub-imgg {
  width: 80px;
  height: 80px;
}
.heart-icon2 {
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: rgba(255, 20, 147, 0.5);
  cursor: pointer;
  margin-left: 2rem;
  border: 1px solid #d327a1;
}
.soc-img {
  width: 24px;
  margin-top: 10px;
}
.emb-price {
  font-size: 16px;
}

@media (max-width: 768px) {
  .sub-imgg {
    width: 60px;
    height: 60px;
  }
  .heart-icon2 {
    width: 40px;
    height: 30px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: rgba(255, 20, 147, 0.5);
    cursor: pointer;
    margin-left: 2rem;
    border: 1px solid #d327a1;
  }
  .emb-price {
    font-size: 10px;
  }
  .embroidery-img {
    height: auto;
  }
}
:where(.css-dev-only-do-not-override-3rel02).ant-pagination
  .ant-pagination-item-active {
  border-color: #fff !important;
}
:where(.css-3rel02).ant-pagination .ant-pagination-item-active {
  border-color: #fff !important;
}
